import Image from 'next/image'

import { TitleLabelPrimary } from '../elements/labels/TitleLabels'

type HeroSectionProps = {
  header?: React.ReactNode
  supertitleLabel?: string | null
  logo?: string | null
  logoAlt?: string | null
  logoUrl?: string | null
  title: string
  largeTitle?: boolean
  subtitleLabel?: string
  backgroundImageURL: string
  backgroundImageAlt: string
  backgroundImageLQIP?: string | null
  children?: React.ReactNode
  centeredLabels?: boolean
}

type LogoLinkProps = {
  logoUrl?: string | null
  children: React.ReactNode
}

const LogoLink = ({ logoUrl, children }: LogoLinkProps) => {
  if (logoUrl) {
    return (
      <a href={logoUrl} className="cursor-pointer" target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    )
  } else {
    return <>{children}</>
  }
}

export default function HeroSection({
  header,
  supertitleLabel,
  logo,
  logoAlt,
  logoUrl,
  title,
  largeTitle = false,
  subtitleLabel,
  backgroundImageURL,
  backgroundImageLQIP = null,
  backgroundImageAlt,
  children,
  centeredLabels,
}: HeroSectionProps) {
  const centerHeroContent = centeredLabels ? 'items-center justify-center text-center' : ''
  return (
    <header className="relative flex flex-col bg-black-alt pb-10">
      {/* Image background - only cover the title and titlelabel */}
      <div className="-z-1 absolute bottom-0 left-0 right-0 top-0  ">
        <Image
          fill
          className="object-cover"
          src={backgroundImageURL}
          alt={backgroundImageAlt}
          priority
          placeholder={backgroundImageLQIP ? 'blur' : 'empty'}
          blurDataURL={backgroundImageLQIP ?? ''}
        />
        <div className="absolute h-full w-full  bg-gradient-to-b from-transparent via-black-alt/[.35] to-black-alt"></div>
      </div>

      {/* title and subtitle container */}
      <div className={`${centerHeroContent} relative flex`}>
        {/* title and title label */}
        <div className="container z-10 grid grid-cols-12  pt-[8.0rem] md:pt-[16rem]">
          <div className="col-span-12">{header}</div>
          <div className="col-span-12 text-white md:col-span-7">
            {logo && logoAlt && (
              <div className="relative mb-5 h-[75px] w-[200px]">
                <LogoLink logoUrl={logoUrl}>
                  <Image
                    fill
                    className="object-contain"
                    src={logo}
                    alt={logoAlt}
                    sizes="200px"
                    style={{ objectPosition: 'left' }}
                  />
                </LogoLink>
              </div>
            )}
            {supertitleLabel && (
              <span className="mb-5 block font-super-new text-lg uppercase  text-sea-foam md:mb-11 md:text-2.2xl">
                {supertitleLabel}
              </span>
            )}
            <TitleLabelPrimary largeFont={largeTitle}>{title}</TitleLabelPrimary>
            {subtitleLabel && (
              <span className="mb-5 block font-super-new text-lg uppercase text-grey-text3 md:mb-11 md:text-2.2xl">
                {subtitleLabel}
              </span>
            )}
          </div>
        </div>
      </div>

      {/* body container */}
      <div className="container z-10">{children}</div>
    </header>
  )
}
