import {
  ExclamationCircleIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/solid'

type Props = {
  variant: 'info' | 'alert' | 'warning'
  message: string
}

export default function Alert({ variant, message }: Props) {
  function icon() {
    switch (variant) {
      case 'info':
        return <InformationCircleIcon className="h-5 w-5 text-salmon" />
      case 'alert':
        return <ExclamationCircleIcon className="h-5 w-5 text-salmon" />
      case 'warning':
        return <ShieldExclamationIcon className="h-5 w-5 text-salmon" />
      default:
        return <InformationCircleIcon className="h-5 w-5 text-salmon" />
    }
  }
  return (
    <div className="flex w-full items-center justify-center bg-salmon-dark/[0.6]">
      <div className="flex flex-row items-center gap-2 p-3 text-xs md:text-sm">
        {icon()}
        <span className="text-salmon-light">{message}</span>
      </div>
    </div>
  )
}
