import { Dialog } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'

import { ForegroundVideoPlayer } from './videoPlayer'

// Render a modal full-screen video player for the given url

type ModalVideoPlayerProps = {
  open: boolean
  setIsOpen: (open: boolean) => void
  url: string
}

export default function ModalVideoPlayer({ open, setIsOpen, url }: ModalVideoPlayerProps) {
  return (
    <Dialog open={open} onClose={() => setIsOpen(false)} className="relative z-50">
      <div className="fixed inset-0 bg-black/70 backdrop-blur-lg" aria-hidden="true" />
      <div className="fixed inset-0 flex flex-col items-center justify-center ">
        {/* The actual dialog panel  */}

        <div className="container flex w-full flex-row justify-end p-0 pb-4">
          <button
            className="focus:outline-none"
            onClick={() => {
              setIsOpen(false)
            }}
          >
            <XIcon className="h-8 w-8 text-gray-400" />
          </button>
        </div>

        <Dialog.Panel className="container p-0 text-white">
          <ForegroundVideoPlayer url={url} />
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}
