import { RefObject, SyntheticEvent, createContext, useContext } from 'react'

// Player state for a specific clip
export type ClipPlaybackState = {
  // current playback location [0..1]
  currentProgress: number

  // percent complete [0..1]
  furthestProgress: number

  // "effectively" complete. May be true even when progress < 1.0
  isComplete: boolean

  // Media url
  url: string

  // duration
  duration: number
}

export type PlayerContextType = {
  // Video ref
  videoRef: RefObject<HTMLVideoElement> | null

  // True if a player is currently active on the page
  playerIsActive: boolean

  // Map of Clip ID => ClipPlaybackState
  clipStateMap: { [itemID: string]: ClipPlaybackState }

  playbackRate: number

  // ID of the clip currently playing
  activeClipID: string
  setActiveClip: (clipID: string) => void

  // number of seconds to offset when initially loading
  // the clip. read-only.
  resumeOffset: number

  // User's preferred audio track locale
  selectedAudioTrackLocale: string | null

  // User's subtitle selection
  selectedSubtitleTrack: string | null

  // player implementation should forward events here:
  playerDidUpdateTime: (event: SyntheticEvent<HTMLVideoElement, Event>) => void
  playerDidUpdatePlaybackRate: (event: SyntheticEvent<HTMLVideoElement, Event>) => void
  playerEnded: (event: SyntheticEvent<HTMLVideoElement, Event>) => void
  playerPaused: (event: SyntheticEvent<HTMLVideoElement, Event>) => void
  playerStart: (event: SyntheticEvent<HTMLVideoElement, Event>) => void
  audioTrackChanged: (locale: string) => void
  subtitleTrackChanged: (locale: string | null) => void
}

export const defaultPlayerContext: PlayerContextType = {
  videoRef: null,
  playerIsActive: false,
  clipStateMap: {},
  activeClipID: '',
  playbackRate: 1,
  selectedAudioTrackLocale: null,
  selectedSubtitleTrack: null,
  setActiveClip: () => {},
  resumeOffset: 0,
  playerDidUpdateTime: () => {},
  playerDidUpdatePlaybackRate: () => {},
  playerEnded: () => {},
  playerPaused: () => {},
  playerStart: () => {},
  audioTrackChanged: () => {},
  subtitleTrackChanged: () => {},
}
export const PlayerContext = createContext<PlayerContextType>(defaultPlayerContext)

export function usePlayerContext() {
  return useContext(PlayerContext)
}
