import Hls, {
  AudioTrackSwitchedData,
  Events,
  ManifestLoadedData,
  MediaPlaylist,
  SubtitleTrackSwitchData,
} from 'hls.js'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Alert from '~/components/elements/rows/Alert'
import { usePlayerContext } from '~/lib/state_management/context/playerContext'

interface PlayerProps extends React.VideoHTMLAttributes<HTMLVideoElement> {
  initialOffset?: number
  url: string
  onAudioTrackChange?: (locale: string) => void
  onSubtitleTrackChange?: (locale: string | null) => void
  className?: string
}

export function BackgroundVideoPlayer(props: PlayerProps) {
  return <HLSVideoPlayer muted autoPlay loop {...props} className="object-cover" />
}

export function ForegroundVideoPlayer(props: PlayerProps) {
  return <HLSVideoPlayer autoPlay controls {...props} />
}

function HLSVideoPlayer({
  onAudioTrackChange,
  onSubtitleTrackChange,
  children,
  className,
  ...props
}: PlayerProps) {
  const { i18n } = useTranslation()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const playerContext = usePlayerContext()

  // Prefer to use the ref in the playerContext (IE the class player)
  // if that's unavailable, use our own ref (trailers, standalone videos, etc)
  const { videoRef: contextVideoRef } = playerContext
  const localVideoRef = useRef<HTMLVideoElement>(null)
  const videoRef = contextVideoRef || localVideoRef

  const { url, initialOffset, ...forwardedProps } = props
  const languageNotFoundErrorMessage = 'This video is not yet available in your preferred language.'

  useEffect(() => {
    const selectedLocale = playerContext.selectedAudioTrackLocale || i18n.language
    let hls: Hls

    async function loadAndStartPlayer() {
      if (videoRef?.current) {
        const video = videoRef.current
        const isAndroid = navigator.userAgent.toLowerCase().includes('android')

        // Do not check for 'maybe' or 'probably' (canPlayType return value)
        if (!isAndroid && video.canPlayType('application/vnd.apple.mpegurl')) {
          // Prefer native HLS if available
          //  - Safari
          //  - Mobile Safari

          video.src = url
          const audioTrackList = video.audioTracks
          let tracks: AudioTrack[]

          // Only the ForegroundVideoPlayer passes through onAudioTrackChange
          if (onAudioTrackChange && audioTrackList) {
            // When a user selects a new language, store that in the
            // playerContext so that it can be used on next clip load.
            //
            // Known bug: When a user clicks on a clip that doesn't host
            // their chosen language, if they have previously chosen a
            // language for the clip they're navigating to, they will be
            // set to that language for future clips.
            audioTrackList.addEventListener('change', function () {
              tracks = Object.values(audioTrackList)
              const preferredAudioTrack = tracks.find((track: AudioTrack) => track.enabled)
              if (preferredAudioTrack) {
                onAudioTrackChange(preferredAudioTrack.language || 'en')
              }
            })

            // When clip loads, grab the playerContext data and switch to
            // the correct audio track if necessary.
            video.addEventListener('loadeddata', function () {
              tracks = Object.values(audioTrackList)
              const preferredAudioTrack = tracks.find((track: AudioTrack) => {
                // we assume a falsey locale is English
                const trackLang = track.language || 'en'
                return trackLang === selectedLocale
              })

              if (preferredAudioTrack) {
                // If track length === 1, there is only 1 audio track and we don't have to
                // switch anything
                if (tracks.length > 1) {
                  const mainAudioTrack = tracks.find((track) => track.enabled)

                  if (mainAudioTrack && preferredAudioTrack) {
                    setErrorMessage(null)
                    const enabled = preferredAudioTrack.enabled
                    preferredAudioTrack.enabled = mainAudioTrack.enabled
                    mainAudioTrack.enabled = enabled
                  }
                }
              } else {
                setErrorMessage(languageNotFoundErrorMessage)
              }
            })
          }
        } else if (Hls.isSupported()) {
          // Fallback to HLS.js if it's supported (https://github.com/video-dev/hls.js#compatibility)
          //  - Chrome
          //  - Firefox
          //  - Edge
          //  - Chrome on Android (we're manually forcing HLS.js in this case, see `if` higher up)

          hls = new Hls()

          // Added in code for our playerContext audioTrack var in case we want
          // to add in a toggle for users to be able to set their pref language in-page.
          // Only the TClassPlayer passes through onAudioTrackChange
          if (onAudioTrackChange) {
            hls.on(Hls.Events.MANIFEST_LOADED, (event: string, data: ManifestLoadedData) => {
              const preferredAudioTrackId = data.audioTracks.find((track: MediaPlaylist) => {
                // we assume a falsey locale is English
                const trackLang = track.lang || 'en'
                return trackLang === selectedLocale
              })?.id

              hls.startLoad()

              if (preferredAudioTrackId) {
                setErrorMessage(null)
                hls.audioTrack = preferredAudioTrackId
              } else if (selectedLocale !== 'en') {
                // if we didn't find a track for the user's language we are using the default (english)
                // so display a warning if user's language is not english
                setErrorMessage(languageNotFoundErrorMessage)
              }
            })

            // Handle a HLS.js bug where, when a non-default audio track is selected,
            // the player doesn't switch its audio track immediately upon player load.
            // It instead plays the default audio track for a few seconds and then switches.
            // Here, once we see that the audio track has switched, reload the player
            // so that the audio and video are synced.
            hls.on(
              Hls.Events.AUDIO_TRACK_SWITCHED,
              (event: Events.AUDIO_TRACK_SWITCHED, data: AudioTrackSwitchedData) => {
                hls.startLoad()
              }
            )
          }

          if (onSubtitleTrackChange) {
            hls.on(Hls.Events.MANIFEST_LOADED, (event: string, data: ManifestLoadedData) => {
              const selectedSubtitleTrackId =
                hls.subtitleTracks.find((track: MediaPlaylist) => {
                  return (
                    // If "en-US", "es-US", etc are coming in, grab the "en"/"es" part to compare
                    playerContext.selectedSubtitleTrack?.split('-')[0] === track.lang?.split('-')[0]
                  )
                })?.id ?? -1
              hls.subtitleTrack = selectedSubtitleTrackId
            })

            hls.on(
              Hls.Events.SUBTITLE_TRACK_SWITCH,
              (event: Events.SUBTITLE_TRACK_SWITCH, data: SubtitleTrackSwitchData) => {
                const selectedSubtitleTrackLang =
                  hls.subtitleTracks.find((track: MediaPlaylist) => {
                    return data.id === track.id
                  })?.lang || null
                onSubtitleTrackChange(selectedSubtitleTrackLang)
              }
            )
          }

          hls.loadSource(url)
          hls.attachMedia(video)
        } else {
          console.error("This is a legacy browser that doesn't support MSE")
        }

        video.load()
        if (initialOffset) {
          video.currentTime = initialOffset
        }
        if (props.autoPlay) {
          const playPromise = video.play()

          if (playPromise !== undefined) {
            playPromise.catch(() => {
              // Auto-play was prevented for any number of reasons.
              //
              // OK with a no-op here since in all cases
              // we don't want to do any special workarounds. Just
              // let the user manually start.
            })
          }
        }
      }
    }

    loadAndStartPlayer()

    return () => {
      if (hls) {
        hls.off(Hls.Events.MANIFEST_LOADED)
        hls.off(Hls.Events.AUDIO_TRACK_SWITCHED)
        hls.off(Hls.Events.SUBTITLE_TRACK_SWITCH)
        hls.destroy()
      }
    }
    // The other props that React wants us to add are disruptive to the loading
    // of the loadAndStartPlayer() function. Purposefully ignoring them.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef, props.autoPlay, url, initialOffset])

  return (
    // NOTE: Do not use the autoplay tag here until we're on 100% react pages.
    // See: https://linear.app/tinyhood-dev/issue/TIN-621/react-video-autoplay-bug
    <>
      <video
        ref={videoRef}
        id="video"
        className={`w-full ${className}`}
        {...forwardedProps}
        playsInline
      />
      <div className="flex flex-col justify-end bg-neutral-800 lg:flex-row">
        {errorMessage && <Alert variant="info" message={errorMessage} />}
        {children}
      </div>
    </>
  )
}
